<template>
    <div class="main-profile">
        <div class="profile-general-container">
            <div class="profile">
                <Header />
                <Wrapper>
                    <div class="profile-general">
                        <h1 class="profile-header">{{ $t('generalTabs.profileSettings') }}</h1>
                        <ProfileItem v-for="item in profileItemsArray" :key="item.id" :item="item" />
                    </div>
                </Wrapper>
            </div>
            <PremiumClubAdditional :onToggleDontCallMe="onToggleDontCallMe" :createdFunc="createdFunc" />
        </div>
        <Envelope />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: ['onToggleDontCallMe', 'createdFunc'],
    computed: {
        ...mapGetters(['clientObj', 'brandName']),
    },
    data() {
        const profileItemsArray = [
            {
                id: 1,
                header: this.$t('global.byUsername'),
                subtext: this.$t('generalTabs.thisIsYourLogin'),
                inputType: 'text',
                inputValue: null
            },
            {
                id: 2,
                header: this.$t('global.email'),
                inputType: 'text',
                inputValue: null
            },
            {
                id: 3,
                header: this.$t('global.password'),
                subtext: this.$t('generalTabs.thisIsYourPassword'),
                inputType: 'password',
                inputValue: null
            },
        ]
        return {
            profileItemsArray
        }
    },
    components: {
        Wrapper: () => import('@/components/Wrapper.vue'),
        Header: () => import('@/components/SideNavPc.vue'),
        ProfileItem: () => import('./components/ProfileSettingsItem.vue'),
        PremiumClubAdditional: () => import('./AdditionalInfo.vue'),
        Envelope: () => import('@/components/Envelope.vue')
    },
    watch: {
        clientObj(value) {
            this.profileItemsArray.forEach((item) => {
                switch (item.id) {
                    case 1:
                        return item.inputValue = value.email
                    case 2:
                        return item.inputValue = value.clientEmail
                    case 3:
                        return item.inputValue = value.password
                }
            })
        }
    },
    async created() {
        await this.createdFunc()
    }
}
</script>

<style scoped>
h1,
p,
button,
input,
label,
a {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.main-profile {
    display: flex;
    justify-content: space-between;
}

.profile {
    width: 100%;
    width: fit-content;
}

.profile-general {
    width: inherit;
}

.profile-header {
    font-size: 20px;
    font-weight: 600;
    color: white;
}

@media only screen and (max-width: 1500px) {
    .profile-general-container {
        width: 70%;
    }

    .profile {
        width: 100%;
    }
}

@media only screen and (max-width: 1090px) {
    .main-profile {
        flex-direction: column;
        align-items: center;
    }

    .profile-general-container {
        width: 100%;
    }
}
</style>